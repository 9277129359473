<mat-form-field appearance="outline" fxFlex="100">
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="itemFormControl" [placeholder]="placeholder" [multiple]="multiple" #customSelect
    [required]="required">
    <mat-option>
      <ngx-mat-select-search [formControl]="filterFormControl" [placeholderLabel]="placeholder"
        [noEntriesFoundLabel]="'NOT_FOUND' | translate"></ngx-mat-select-search>
    </mat-option>
    <!-- <mat-option value="" selected disabled>--{{'OPTION'|translate}}--</mat-option> -->
    <mat-option *ngFor="let item of filteredItems | async" [value]="item"
      matTooltip="{{item.name ? item.name : item.coa +' - '+ item.desc}}">
      {{item.name ? item.name : item.coa +' - '+ item.desc}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="required == true">{{errorLabel}}</mat-error>
</mat-form-field>