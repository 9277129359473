import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectWithSearchComponent } from './select-with-search.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [SelectWithSearchComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    NgxMatSelectSearchModule,
    TranslateModule
  ], 
  exports: [
    SelectWithSearchComponent,
  ]
})
export class SelectWithSearchModule { }
