export const locale = {
  lang: 'en',
  data: {
    'EDIT': 'Edit',
    'ADD': 'Add',
    'REMOVE': 'Remove',
    'ACTIONS': 'Actions',
    'SAVE': 'Save',
    'CANCEL': 'Cancel',
    'ACTIVE': 'Active',
    'INACTIVE': 'Inactive',
    'BUTTON': {
      'RESET': 'Reset',
      'FILTER': 'Apply'
    },
    'PROVINCES': {
      'PAGETITLE': 'Provinces',
      'PAGETITLEACTIONADD': 'Add Province Form',
      'PAGETITLEACTIONEDIT': 'Edit Province Form',
      'COLS': {
        'NAME': 'Province Name',
        'ADM_CITY': 'Adm City',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'This field is required',
      },
    },
    'CITIES': {
      'PAGETITLE': 'Cities',
      'PAGETITLEACTIONADD': 'Add City Form',
      'PAGETITLEACTIONEDIT': 'Edit City Form',
      'LABELS': {
        'PROVINCE': 'Select Province'
      },
      'COLS': {
        'NAME': 'City Name',
        'PROVINCE': 'Province',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'This field is required',
      },
    },
    'DISTRICTS': {
      'PAGETITLE': 'Districts',
      'PAGETITLEACTIONADD': 'Add District Form',
      'PAGETITLEACTIONEDIT': 'Edit District Form',
      'COLS': {
        'NAME': 'District Name',
        'PROVINCE': 'Province',
        'CITY': 'City',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'This field is required',
      },
    },
    'SUB_DISTRICTS': {
      'PAGETITLE': 'Sub Districts',
      'PAGETITLEACTIONADD': 'Add Sub District Form',
      'PAGETITLEACTIONEDIT': 'Edit Sub District Form',
      'LABELS': {
        'PROVINCE': 'Select Province',
        'CITY': 'Select City',
        'DISTRICT': 'Select Distric',
      },
      'COLS': {
        'NAME': 'Sub-district Name',
        'PROVINCE': 'Province',
        'CITY': 'City',
        'DISTRICT': 'District',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'This field is required',
      },
    },
    'FORM': {
      'CASESENSITIVE'  : 'Case sensitive',
    }
  }
};
