import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver-es';

import { environment } from 'environments/environment';
import { BaseApiService } from '../base-api.service';
import { Employee } from './employees';
import { HelpersService } from '../helpers.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends BaseApiService<Employee> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employees';
  }

  exportPdf(id: string): Observable<any> {
    const url = this.url_array([id, 'pdf']);
    const options: Object = {
      responseType: 'blob',
      observe: 'response'
    };
    return this.http.get<any>(url, options).pipe(
      tap({
        next: data => {
          const blob = new Blob([data.body], { type: 'application/pdf '});
          saveAs(blob, this.helper.getDownloadedFileName(data.headers.get('Content-Disposition')));
        }
      })
    );
    // window.location.href = isDevMode() ? `${environment.host}${url}` : url;
  }
}
