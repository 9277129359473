import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { Province } from './region';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends BaseApiService<Province> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'provinces';
  }
}
