import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetRentalApproved } from './assets-rental';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsRentalApprovedService extends BaseApiService<AssetRentalApproved> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'approve-borrowed';
  }

  outApprove(data: any) {
    const url = this.url_custom('out-approve');
    return this.http.post<any>(url, data);
  }

  inApprove(data: any) {
    const url = this.url_custom('in-approve');
    return this.http.post<any>(url, data);
  }

}
