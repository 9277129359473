import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { Certificate } from './certificate';
import { HelpersService } from '../helpers.service';

@Injectable({
    providedIn: 'root'
})
export class CertificateService extends BaseApiService<Certificate> {

    constructor(
        public http: HttpClient,
        public helper: HelpersService
    ) {
        super(http, helper);
        this.endpoint = 'employee-certificates';
    }
}
