export const locale = {
  lang: 'id',
  data: {
    'EDIT': 'Sunting',
    'ADD': 'Tambah',
    'REMOVE': 'Hapus',
    'ACTIONS': 'Aksi',
    'SAVE': 'Simpan',
    'CANCEL': 'Batal',
    'ACTIVE': 'Aktif',
    'INACTIVE': 'Non-aktif',
    'BUTTON': {
      'RESET': 'Reset',
      'FILTER': 'Terapkan'
    },
    'PROVINCES': {
      'PAGETITLE': 'Daftar Provinsi',
      'PAGETITLEACTIONADD': 'Formulir Tambah Provinsi',
      'PAGETITLEACTIONEDIT': 'Formulir Sunting Provinsi',
      'COLS': {
        'NAME': 'Nama Provinsi',
        'ADM_CITY': 'Kota Madya',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'Wajib diisi!',
      },
    },
    'CITIES': {
      'PAGETITLE': 'Daftar Kota',
      'PAGETITLEACTIONADD': 'Formulir Tambah Kota',
      'PAGETITLEACTIONEDIT': 'Formulir Sunting Kota',
      'LABELS': {
        'PROVINCE': 'Pilih Provinsi'
      },
      'COLS': {
        'NAME': 'Nama Kota',
        'PROVINCE': 'Provinsi',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'Wajib diisi!',
      },
    },
    'DISTRICTS': {
      'PAGETITLE': 'Daftar Kecamatan',
      'PAGETITLEACTIONADD': 'Formulir Tambah Kecamatan',
      'PAGETITLEACTIONEDIT': 'Formulir Sunting Kecamatan',
      'COLS': {
        'NAME': 'Nama Kecamatan',
        'PROVINCE': 'Provinsi',
        'CITY': 'Kota',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'Wajib diisi!',
      },
    },
    'SUB_DISTRICTS': {
      'PAGETITLE': 'Daftar Kelurahan',
      'PAGETITLEACTIONADD': 'Formulir Tambah Kelurahan',
      'PAGETITLEACTIONEDIT': 'Formulir Sunting Kelurahan',
      'LABELS': {
        'PROVINCE': 'Pilih Provinsi',
        'CITY': 'Pilih Kota',
        'DISTRICT': 'Pilih Kecamatan',
      },
      'COLS': {
        'NAME': 'Nama Kelurahan',
        'PROVINCE': 'Provinsi',
        'CITY': 'Kota',
        'DISTRICT': 'Kecamatan',
        'STATUS': 'Status'
      },
      'VALIDATORS': {
        'REQUIRED': 'Wajib diisi!',
      },
    },
    'FORM': {
      'CASESENSITIVE'  : 'Kepekaan kapital',
    }
  }
};
