import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Access } from './access';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService extends BaseApiService<Access> {
  accesses$: BehaviorSubject<Access[]>;
  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'accesses';
  }
  getAccesses(): void{
    this.getAll({limit: 0}).subscribe(({data}) => {
      this.accesses$.next(data);
    });
  }
  export(): void {
    window.location.href = this.url_custom('export');
  }
}
