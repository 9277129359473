import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { Vendors } from './vendors';

@Injectable({
  providedIn: 'root'
})
export class VendorsService extends BaseApiService<Vendors> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'vendors';
  }
}
