import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { District } from './region';

@Injectable({
  providedIn: 'root'
})
export class DistrictService extends BaseApiService<District> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'districts';
  }
}
