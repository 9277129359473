import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Banks } from './banks';
import { BaseAPIArray, BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService extends BaseApiService<Banks> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'bank-accounts';
  }

  getTypes(): Observable<BaseAPIArray<any>>{
    const url = this.url_custom('types');
    return this.http.get<any>(url);
  }
}
