import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIArray, BaseApiService } from './base-api.service';
import { Coa } from './coa';
import { HelpersService } from './helpers.service';
import { HttpQuery } from './http-query';

@Injectable({
  providedIn: 'root'
})
export class CoaService extends BaseApiService<Coa> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'coas';
  }

  getAllList(query?: HttpQuery): Observable<BaseAPIArray<Coa>> {
    const url = this.url_custom('list');
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.get<BaseAPIArray<Coa>>(url, { params });
  }

  getAllCoa(where, query?: HttpQuery): Observable<BaseAPIArray<Coa>> {
    const url = this.url_custom('all');
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.post<BaseAPIArray<Coa>>(url, where, { params });
  }
}
