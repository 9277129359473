import { Majors } from "../master-employee";
import { Employee } from "./employees";

export interface Education {
  id: number;
  is_formal: boolean;
  institution: string;
  faculty: string;
  level: string;
  file_ijazah: any;
  file_certificate: any;
  city: string;
  year: string;
  desc: string;
  major_id: number;
  major: Majors;
  start_date: string;
  end_date: string;
  employee_id: number;
  employee: Employee[];
}

export enum EducationLevel {
  PRIMARY_SCHOOL = 'SD',
  MIDDLE_SCHOOL = 'SMP',
  HIGH_SCHOOL = 'SMA/SMK',
  UNDERGRADUATE_ASSOCIATE_DEGREE_A = 'D1',
  UNDERGRADUATE_ASSOCIATE_DEGREE_B = 'D2',
  UNDERGRADUATE_ASSOCIATE_DEGREE_C = 'D3',
  UNDERGRADUATE_ASSOCIATE_DEGREE_D = 'D4',
  GRADUATE_DEGREE = 'S1',
  POSTGRADUATE_DEGREE = 'S2',
  DOCTORAL_DEGREE = 'S3'
}
