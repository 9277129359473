<!-- CLOSE BUTTON -->
<button type="button" class="dialog-close-button" (click)="close()">
  <span class="fas fa-fw fa-times"></span>
</button>
<!-- / CLOSE BUTTON -->

<!-- TITLE -->
<h1 mat-dialog-title class="h2">
  {{trans.currentLang == 'id' ? 'Unggah Gambar' : 'Upload Image'}}
</h1>
<!-- / TITLE -->

<!-- CONTENT -->
<mat-dialog-content>
  <app-file-dump-upload [uploadUrl]="uploadUrl"
                        [folderPath]="folderPath"
                        [category]="category"
                        (done)="onUploaded($event)"
                        (uploading)="uploading($event)"
                        [fileAccept]="fileAccept"
                        [multi]="false" style="width: 100% !important;">
  </app-file-dump-upload>
  <div *ngIf="uploaded.length>0 && isProgress==100" class="alert alert--success">
    {{trans.currentLang == 'id' ? 'Data telah diunggah. Klik tombol simpan untuk menyimpan perubahan.' : 'Data uploaded. Click button save for save the changes'}}
  </div>
</mat-dialog-content>
<!-- / CONTENT -->

<!-- ACTIONS -->
<mat-dialog-actions align="end">
  <button mat-stroked-button color="basic" type="button" (click)="close()">{{'CANCEL' | translate}}</button>
  <button mat-flat-button color="primary" type="submit" [disabled]="uploaded.length==0 || isProgress<100" (click)="save()">{{trans.currentLang == 'id' ? 'Simpan' : 'Save'}}</button>
</mat-dialog-actions>
<!-- / ACTIONS -->

<!-- <div fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
    <h1 class="h2 mt-0" fxFlex="100">{{trans.currentLang == 'id' ? 'Unggah Gambar' : 'Upload Image'}}</h1>
    <hr>
    <app-file-dump-upload [uploadUrl]="uploadUrl" [folderPath]="folderPath" [category]="category"
      (done)="onUploaded($event)" (uploading)="uploading($event)" [multi]="false" style="width: 100% !important;">
    </app-file-dump-upload>
  </div>
</div>
<div fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
    <div *ngIf="uploaded.length>0 && isProgress==100" class="mt-16 alert alert-success">
      {{trans.currentLang == 'id' ? 'Data telah diunggah. Klik tombol simpan untuk menyimpan perubahan.' : 'Data uploaded. Click button save for save the changes'}}
    </div>
    <button *ngIf="uploaded.length>0 && isProgress==100" mat-raised-button class="primary"
      (click)="save()">{{trans.currentLang == 'id' ? 'Simpan' : 'Save'}}</button>
  </div>
</div> -->
