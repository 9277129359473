import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesLabelComponent } from './roles-label.component';



@NgModule({
  declarations: [RolesLabelComponent],
  imports: [
    CommonModule
  ],
  exports: [
    RolesLabelComponent
  ]
})
export class RolesLabelModule { }
