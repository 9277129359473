import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { Payments } from './payments';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService extends BaseApiService<Payments>{

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'payments';
  }

  getTotalPayment(where){
    const url = this.url_custom('total');
    return this.http.post<any>(url, where)
  }
}
