import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { Language } from './language';
import { HelpersService } from '../helpers.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseApiService<Language> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employee-languages';
  }
}
