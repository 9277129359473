import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { locale as en } from './i18n/en';
import { locale as id } from './i18n/id';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss']
})
export class SelectWithSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemId: number = null;
  @Input() items: any[] = [];
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() optionName: string = 'name';
  @Input() label: string;
  @Input() required: boolean;
  @Input() errorLabel: string;
  @Output() selectedItem = new EventEmitter();
  item: any;
  itemFormControl: FormControl = new FormControl({value: '', disabled: true});
  filterFormControl: FormControl = new FormControl();
  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  protected unsubs = new Subject();

  constructor(
    public fuseTranslationLoader: FuseTranslationLoaderService,
  ) {
    this.fuseTranslationLoader.loadTranslations(en, id);
  }

  ngOnInit(): void {
    this.filterFormControl
      .valueChanges
      .pipe(takeUntil(this.unsubs))
      .subscribe(
        () => this.filterItem()
      );

    this.itemFormControl
      .valueChanges
      .pipe(takeUntil(this.unsubs))
      .subscribe(
        (val) => this.selectItem(val)
      );
  }

  ngOnDestroy(): void{
    this.unsubs.next();
    this.unsubs.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const { items } = changes;
      if (items && items.previousValue != items.currentValue){
        if (this.itemId){
          this.item = this.items.find(item => item.id == this.itemId);
          this.itemFormControl.setValue(this.item);
        }
        this.itemFormControl.enable();
        this.filteredItems.next(items.currentValue);
      }
    }
  }

  filterItem(){
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.filterFormControl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the items
    this.filteredItems.next(
      // this.items.filter(item => item[this.optionName].toLowerCase().indexOf(search) > -1)
      this.items.filter(item => {
        let q
        if (item.name) {
          q = item.name.toLowerCase().indexOf(search)
        } else {
          const combineQuery = `${item.coa} - ${item.desc}`
          q = combineQuery.toLowerCase().indexOf(search)
        }

        return q > -1
      })
    );
  }

  selectItem(val){
    if (val) {
      this.selectedItem.emit(val.id);
    }
  }
}
