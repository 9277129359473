import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SlugifyPipe } from 'ngx-pipes';
import { MineTypeEnum } from '@wkoza/ngx-upload';

@Component({
  selector: 'app-image-dialog-upload',
  templateUrl: './image-dialog-upload.component.html',
  styleUrls: ['./image-dialog-upload.component.scss'],
  providers: [SlugifyPipe]
})
export class ImageDialogUploadComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImageDialogUploadComponent>,
    private slugify: SlugifyPipe,
    public trans: TranslateService
  ) {
    const folder_name = this.data.categoryVal;
    this.folderPath = `${this.slugify.transform(folder_name)}`;
  }

  folderPath: string;
  uploadUrl = '/api/upload-dumps';
  uploaded = [];
  isProgress = 0;
  category = this.data.categoryVal;
  fileAccept = [MineTypeEnum.Image];
  ngOnInit(): void {
    this.uploaded = [];
  }
  onUploaded(e) {
    this.uploaded.push(e);
  }
  uploading(e) {
    this.isProgress = e.progress;
  }
  save() {
    this.dialogRef.close(this.uploaded);
  }
  close():void {
    this.dialogRef.close();
  }

}
