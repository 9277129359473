import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuctionSdm } from './auction-sdm';
import { BaseAPIArray, BaseApiService } from './base-api.service';
import { Employee } from './employees';
import { HelpersService } from './helpers.service';
import { HttpQuery } from './http-query';

@Injectable({
  providedIn: 'root'
})
export class AuctionSdmService extends BaseApiService<AuctionSdm>{

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'project-sdm';
  }

  getEmployees(query?: HttpQuery): Observable<BaseAPIArray<Employee>> {
    const url = this.url_custom('employees');
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.get<BaseAPIArray<Employee>>(url, { params });
  }

  getEmployeeExperience(employeeId: any, query?: HttpQuery): Observable<BaseAPIArray<Employee>> {
    const url = this.url_array(['experiences', employeeId]);
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.get<BaseAPIArray<Employee>>(url, { params });
  }
}
