import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { Education } from './education';
import { HelpersService } from '../helpers.service';

@Injectable({
  providedIn: 'root'
})
export class EducationService extends BaseApiService<Education> {

  constructor(
      public http: HttpClient,
      public helper: HelpersService
  ) {
      super(http, helper);
      this.endpoint = 'employee-educations';
  }
}
