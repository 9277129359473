import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  regionTitle$: BehaviorSubject<string>;
  add$: BehaviorSubject<string>;
  removeAll$: BehaviorSubject<string>;
  deleteSelected$: BehaviorSubject<string>;
  currentFilter$: BehaviorSubject<string>;
  selectChange$: BehaviorSubject<any[]>;
  applyFilter$: BehaviorSubject<any>;
  constructor() {
    this.regionTitle$ = new BehaviorSubject(null);
    this.removeAll$ = new BehaviorSubject(null);
    this.add$ = new BehaviorSubject(null);
    this.deleteSelected$ = new BehaviorSubject(null);
    this.currentFilter$ = new BehaviorSubject(null);
    this.selectChange$ =  new BehaviorSubject([]);
    this.applyFilter$ =  new BehaviorSubject({});
  }
}
