import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roles-label',
  templateUrl: './roles-label.component.html',
  styleUrls: ['./roles-label.component.scss']
})
export class RolesLabelComponent implements OnInit {

  @Input() roleName: string;
  role: string;

  constructor() { }

  ngOnInit(): void {
    this.setRole();
  }

  setRole() {
    if (this.roleName == 'superadmin' || this.roleName == 'super admin' || this.roleName == 'Superadmin' || this.roleName == 'Super Admin') {
      this.role = 'superadmin'
    } else if (this.roleName == 'admin' || this.roleName == 'Admin') {
      this.role = 'admin'
    } else if (this.roleName == 'logistic' || this.roleName == 'Logistic') {
      this.role = 'logistic'
    } else if (this.roleName == 'monetary' || this.roleName == 'Monetary') {
      this.role = 'monetary'
    } else if (this.roleName == 'project' || this.roleName == 'Project') {
      this.role = 'project'
    } else {
      this.role = 'default'
    }
  }

}
