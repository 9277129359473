import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { EmployeeMonitor } from './employees-monitor';
import { HelpersService } from '../helpers.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMonitorService extends BaseApiService<EmployeeMonitor> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employees/monitoring';
  }
}
