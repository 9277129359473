<ng-container [ngSwitch]="role" roleName="roleName">

  <ng-container *ngSwitchCase="'superadmin'">
    <span class="indicators primary-bg mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCase="'admin'">
    <span class="indicators accent-bg mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCase="'logistic'">
    <span class="indicators blue-bg mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCase="'monetary'">
    <span class="indicators green-bg mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCase="'project'">
    <span class="indicators pink-bg mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCase="'default'">
    <span class="indicators mr-4"></span> {{roleName}}
  </ng-container>

  <ng-container *ngSwitchCaseDefault>
    <span class="indicators mr-4"></span> {{roleName}}
  </ng-container>

</ng-container>
